#vintage-background
{
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100000;
  pointer-events: none;
}

.grain
{
  z-index: 100;
  pointer-events: none;
  position: fixed;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  width: 200%;
  height: 200vh;
  background: transparent url('../../images/grain.png') repeat 0 0;
  background-repeat: repeat;
  animation: grainy .2s infinite;
  opacity: 1;
  visibility: visible;
}

.film-scratch
{
  z-index: 100;
  pointer-events: none;
  position: fixed;
  width: 100%;
  height: 100vh;
}

.film-scratch div {
  content: '';
  width: 100%;
  height: 100%;
  z-index: 100;
  pointer-events: none;
  position: fixed;
  opacity: 0.5;
  background: linear-gradient(90deg, rgb(39, 39, 39) 0 0.1rem, transparent 0.2rem);;
}

.scratch-1 div
{
  top: 0;
  left: 70%;
  -webkit-animation: scratch-1 0.45s ease infinite;
  animation: scratch-1 0.45s ease infinite;

}

.scratch-2 div
{
  top: 0;
  left: -4%;
  -webkit-animation: scratch-2 2s ease infinite;
  animation: scratch-1 2s ease;
}



@for $i from 0 through 6 
{
  @keyframes scratch-#{$i}
  {
    0%, 100% {
      transform: translateX(0);
    }
  
    10% {
      opacity: 1;
      transform: translateX($i * 2%);
    }

    20% {
      transform: translateX($i * 4%);
    }
  
    30% {
      transform: translateX($i * 6%);
    }
  
    40% {
      transform: translateX($i * 8%);
    }
  
    50% {
      transform: translateX($i * 10%);
    }
  
    60% {
      transform: translateX($i * 8%);
    }
  
    70% {
      transform: translateX($i * 6%);
    }
  
    80% {
      transform: translateX($i * 4%);
    }
  
    90% {
      transform: translateX($i * 2%);
    }
  }
  @-webkit-keyframes scratch-#{$i}
  {
    0%, 100% {
      transform: translateX(0);
    }
  
    10% {
      opacity: 1;
      transform: translateX($i * 2%);
    }

    20% {
      transform: translateX($i * 4%);
    }
  
    30% {
      transform: translateX($i * 6%);
    }
  
    40% {
      transform: translateX($i * 8%);
    }
  
    50% {
      transform: translateX($i * 10%);
    }
  
    60% {
      transform: translateX($i * 8%);
    }
  
    70% {
      transform: translateX($i * 6%);
    }
  
    80% {
      transform: translateX($i * 4%);
    }
  
    90% {
      transform: translateX($i * 2%);
    }
  }
  
  @-o-keyframes scratch-#{$i}
  {
    0%, 100% {
      transform: translateX(0);
    }
  
    10% {
      opacity: 1;
      transform: translateX($i * 2%);
    }

    20% {
      transform: translateX($i * 4%);
    }
  
    30% {
      transform: translateX($i * 6%);
    }
  
    40% {
      transform: translateX($i * 8%);
    }
  
    50% {
      transform: translateX($i * 10%);
    }
  
    60% {
      transform: translateX($i * 8%);
    }
  
    70% {
      transform: translateX($i * 6%);
    }
  
    80% {
      transform: translateX($i * 4%);
    }
  
    90% {
      transform: translateX($i * 2%);
    }
  }
  @-moz-keyframes scratch-#{$i}
  {
    0%, 100% {
      transform: translateX(0);
    }
  
    10% {
      opacity: 1;
      transform: translateX($i * 2%);
    }

    20% {
      transform: translateX($i * 4%);
    }
  
    30% {
      transform: translateX($i * 6%);
    }
  
    40% {
      transform: translateX($i * 8%);
    }
  
    50% {
      transform: translateX($i * 10%);
    }
  
    60% {
      transform: translateX($i * 8%);
    }
  
    70% {
      transform: translateX($i * 6%);
    }
  
    80% {
      transform: translateX($i * 4%);
    }
  
    90% {
      transform: translateX($i * 2%);
    }
  }
}


.vignette
{
  content: ' ';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  animation: gradientSize 4s infinite;
  -moz-box-shadow: inset 0 0rem 10rem #686868;  
  -webkit-box-shadow: inset 0 0rem 10rem #686868;  
  box-shadow: inset 0 0rem 10rem #686868;
}

@keyframes grainy
{
  0% { transform: translate(0,0) }
  10% { transform: translate(-5%,-5%) }
  20% { transform: translate(-10%,5%) }
  30% { transform: translate(5%,-10%) }
  40% { transform: translate(-5%,15%) }
  50% { transform: translate(-10%,5%) }
  60% { transform: translate(15%,0) }
  70% { transform: translate(0,10%) }
  80% { transform: translate(-15%,0) }
  90% { transform: translate(10%,5%) }
  100% { transform: translate(5%,0) }
}


@keyframes gradientSize 
{
  0%{
    opacity: 0.3;
  }
  10%{
    opacity: 0.4;
  }
  20%{
    opacity: 0.25;
  }
  30%{
    opacity: 0.5;
  }
  40%{
    opacity: 0.75;
  }
  50%{
    opacity: 0.5;
  }
  60%{
    opacity: 1;
  }
  70%{
    opacity: 0.75
  }
  80%{
    opacity: 0.5;
  }
  90%{
    opacity: 0.25;
  }
  100%{
    opacity: 0.3;
  }
}