@use "./animations";
// Screen Width for responsive as fuck design
$screen-width: (
    "phone": 400px,
    "tablet": 768px,
    "desktop": 1366px,
    "720p": 1280px,
    "1080p": 1920px,
    "4k": 3840px
) !default;

$primary: 	#b3b3b3;
$secondary: #e98074;
$white-space: #0b0b0d;
$text-color: #d8d8d8;

:export {
    primary: $primary;
    textColor:  $text-color;
    secondary: $secondary;
    whitespace: $white-space;
}

html
{
    padding: 0;
    margin: 0;
    width: 100%;
    line-height: 1.5;
    font-family: VeryNormal, 'Roboto', sans-serif;
    color: $text-color;
    font-size: 20px;

    @media (max-width: map-get($screen-width, "tablet"))
    {
        font-size: 15px;
    }

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

body
{
    width: 100%;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    background-color: $white-space;
    margin: 0;
}

#app
{
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;

}


/* || CSS LAYOUTS || */
.center-horizontal
{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}


// Flex that positions items horizontally for desktop and vertically for mobile
.responsive-flex
{
    display: flex;

    @media (max-width: map-get($screen-width, "tablet")) {
        flex-direction: column;
    }
}

@media (max-width: map-get($screen-width, "tablet")) {
    #root .responsive-flex > *
    {
        width: fit-content;
    }
}


/* || BASIC SHIT || */

@for $i from 1 through 6
{
    h#{$i}
    {
        line-height: 1;
        margin: 0;
        text-shadow: 0px 2px 4px #646464;
    }
}
h1
{
    font-family: TitleCard, Geneva, Tahoma, sans-serif;
    font-size: 6rem;
    font-weight: bold;

}
h2
{
    padding: 0.5rem 0;
    font-size: 2rem;
    // border-bottom: $text-color solid 2px;
    font-family: Fancy, Geneva, Tahoma, sans-serif;
}
h3
{
    font-size: 1.5rem;
    font-family: Heading, Geneva, Tahoma, sans-serif;
    font-weight: normal;
}

p
{
    font-size: 1.2rem;
    text-shadow: 0px 1px 3px $text-color;
}

.line-divider
{
    filter: drop-shadow(0px 1px 1px $text-color);
}



/* || FONTS || */

@font-face {
    font-family: TitleCard;
    src: url("./fonts/FtySkorzhenNcv-Yo02.ttf") ;
}

@font-face {
    font-family: Fancy;
    src: url("./fonts/Yellowtail-mVna.ttf") ;
}

@font-face {
    font-family: Heading;
    src: url("./fonts/Dalmais-nE6R.otf") format("opentype") ;
}

@font-face {
    font-family: VeryNormal;
    src: url("./fonts/OldStyle.ttf");
}



/* || TRANSFORMATIONS || */
.flipped-y
{
    transform: rotate(180deg);
}