@use "../../index.scss";

ul.link-list
{
    list-style-type: none;
    list-style-position: outside;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 0.75rem;
    margin: 0 1rem;
    font-size: 1rem;
}

ul.link-list li a 
{
    font-family: TitleCard, sans-serif;
    text-decoration: none;
    color: index.$text-color;
    text-transform: capitalize;
    transition: all 0.15s ease-in;
}

ul.link-list li a:hover
{
    color: white;
    text-shadow: 0 0 28px index.$text-color;
}

ul.link-list li a:focus
{
    color: white;
    text-shadow: 0 0 28px index.$text-color;
    outline: 0;
}