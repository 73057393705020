@use "../../index";

#quote
{
    user-select: none;
    padding: 0.8rem;
}

#quote blockquote
{
    text-align: center;
    margin: 0;
    padding: 0;
}
#quote blockquote p
{
    text-align: justify;
    display: inline;
}

#quote-content
{
    gap: 0.5rem;
}

