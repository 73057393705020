
@use "../../index";
@use "../../animations";

#loading-screen
{
  width: 100%;
  height: 100%;
  position: fixed;
  animation: animations.$fade-in;
}

#loader 
{
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid index.$primary;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

main
{
  width: 100%;
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex: 1;
  animation: horizontal-shake 0.1s ease infinite;

}


.webpage > *
{
  animation: animations.$fade-in;
  min-width: 50%;
  @media (min-width: map-get(index.$screen-width, "desktop")) {
    max-width: 60%;
  }
}

.title-card
{
  gap: 1rem;
  padding: 2rem 1rem;
  overflow: visible;
  text-align: center;
}

.title-card div{
  width: 80%;
}


@keyframes spin 
{
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@-webkit-keyframes spin 
{
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@-o-keyframes spin 
{
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes horizontal-shake {
  0% { transform: translateX(0) }
  25% { transform: translateX(0.0175rem) }
  50% { transform: translateX(-0.0175rem) }
  75% { transform: translateX(0.0175rem) }
  100% { transform: translateX(0) }
}